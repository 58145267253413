import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["DrawerContextProvider"] */ "/vercel/path0/apps/web/components/header/drawer-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/header/drawer-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/header/drawer-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/header/tab-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/header/theme-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/web/components/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_7r3mlgaoqlsik4jibsgya2fvge/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_7r3mlgaoqlsik4jibsgya2fvge/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@_7r3mlgaoqlsik4jibsgya2fvge/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"roboto\"}");
